import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { GoogleTranslate } from "../../_components/GoogleTranslate";
import { Col, Row, Icon, Paragraph4 } from "../../_components";
import { Hyperlink } from "../../_components/ui";

const Footer = ({ pssOnly = false }) => {
  const theme = useContext(ThemeContext);
  const showProviderAgreementLinks = pssOnly ? providerAgreementLinks : [];

  return (
    <FooterSection theme={theme} id="footer" role="contentinfo">
      <BrandImage className="mb-4 p-0" order={1}>
        <Icon name="logo-full" width="13em" height="2.5em" />
      </BrandImage>
      <Row justify={"space-between"}>
        <OrderedCol justify={"flex-start"} $align={"flex-start"} md="auto" order={2}>
          <BrandImage className="mb-4" order={2}>
            <Icon name="logo-full" width="13em" height="2.5em" />
          </BrandImage>
          <Row className="mb-4 p-0">
            {socailLinks.map((item, index) => {
              return (
                <Hyperlink
                  key={item.aria}
                  href={item.link}
                  aria={item.aria}
                  target={item.target}
                  className={index % 2 === 1 ? "mx-3" : ""}
                >
                  <Icon name={item.icon} height="48px" width="48px" />
                </Hyperlink>
              );
            })}
          </Row>
          <GoogleTranslate color={theme.palette.primaryBlue} />
          <Copyright color={theme.palette.primaryBlue} />
        </OrderedCol>
        <OrderedCol md={2} order={1}>
          {[...showProviderAgreementLinks, ...legalLinks, ...footerLinks].map((item) => {
            return (
              <Hyperlink key={item.link} href={item.link} target={item.target} className="mb-3">
                {item.label}
              </Hyperlink>
            );
          })}
        </OrderedCol>
      </Row>
    </FooterSection>
  );
};

export const Copyright = ({ color }) => {
  return (
    <Paragraph4 $mt={4} color={color || "primary"}>
      &copy; 2016 - {new Date().getFullYear()} BurnAlong, Inc. All&nbsp;Rights&nbsp;Reserved.
    </Paragraph4>
  );
};

const socailLinks = [
  {
    label: "Twitter",
    aria: "BurnAlong Twitter",
    link: "https://twitter.com/burnalong",
    target: "_blank",
    icon: "twitter-circle",
  },
  {
    label: "Facebook",
    aria: "BurnAlong Facebook",
    link: "https://www.facebook.com/goburnalong/",
    target: "_blank",
    icon: "facebook-circle",
  },
  {
    label: "LinkedIn",
    aria: "BurnAlong LinkedIn",
    link: "https://www.linkedin.com/company/burnalong",
    target: "_blank",
    icon: "linkedin-circle",
  },
  {
    label: "Instagram",
    aria: "BurnAlong Instagram",
    link: "https://www.instagram.com/goburnalong/",
    target: "_blank",
    icon: "instagram-square",
  },
];

export const legalLinks = [
  {
    label: "Terms of Service",
    link: "https://www.burnalong.com/terms-of-service",
    target: "_blank",
  },
  {
    label: "Privacy Policy",
    link: "https://www.burnalong.com/privacy-policy",
    target: "_blank",
  },
  {
    label: "Notice at Collection (CA)",
    link: "https://www.burnalong.com/privacy-policy/#info-we-collect",
    target: "_blank",
  },
  {
    label: "Mobile Policy",
    link: "https://www.burnalong.com/terms-of-use-mobile-messaging/",
    target: "_blank",
  },
  {
    label: "Your Privacy Choices",
    link:
      "https://submit-irm.trustarc.com/services/validation/7533e768-0b19-4c4c-8e84-ac51a550bf1e",
    target: "_blank",
  },
];

const providerAgreementLinks = [
  {
    label: "Content Provider Agreement",
    link: "https://static.burnalong.com/documents/BURNALONGCONTENTPROVIDERAGREEMENT10132023v1.4.pdf",
    target: "_blank",
  }
]

const footerLinks = [
  {
    label: "Contact Us",
    link: "https://www.burnalong.com/contact-us/",
    target: "_blank",
  },
  {
    label: "FAQ's",
    link: "https://burnalonghelp.zendesk.com/hc/en-us/categories/1500000042202-Users",
    target: "_blank",
  },
];

const FooterSection = styled.footer`
  background-color: ${(props) => props.theme.palette.neutralWhite};
  margin-top: auto;
  padding: 48px;
  z-index: 1009;
  @media (max-width: 768px) {
    padding: 10px;
    padding-top: 12px;
  }
`;

const BrandImage = styled.div`
  @media (max-width: 768px) {
    display: ${(props) => (props.order === 2 ? "none" : "")};
  }
  @media (min-width: 769px) {
    display: ${(props) => (props.order === 1 ? "none" : "")};
  }
`;
const OrderedCol = styled(Col)`
  @media (max-width: 768px) {
    order: ${(props) => props.order};
    margin-bottom: 1.5em;
    padding-left: 0px;
  }
`;

export default Footer;
